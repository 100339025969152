import { Link } from "react-router-dom";

import { getGameTitle } from "../utils";
import Avatar from "./Avatar";
import Icon from "./Icon";

function ConfigDescriptor(props: { descriptor: ConfigDescriptor }) {
  const { descriptor } = props;

  return (
    <div className="config-descriptor">
      <p className="config-view-badge config-descriptor-header">
        {getGameTitle(descriptor.game as GameID)}
      </p>

      <div className="config-descriptor-content">
        <Link
          to={`/configs/${descriptor.id}`}
          className="config-descriptor-name"
        >
          {descriptor.name}
        </Link>

        <div className="config-descriptor-owner">
          <Link
            to={`/users/${descriptor.ownerName}`}
            className="main-menu-profile-link"
          >
            <Avatar
              src={descriptor.ownerAvatar ?? null}
              userId={null}
              isMutable={false}
              isSmall={true}
            />
            <p className="config-descriptor-owner">{descriptor.ownerName}</p>
          </Link>
        </div>
      </div>

      <div className="config-descriptor-created-ago">
        <Icon name="clock" />
        {props.descriptor.createdAgo}
      </div>
    </div>
  );
}

export default ConfigDescriptor;
