import React from "react";

import Toast from "../components/Toast";
import "../styles/components/toast.sass";

class ErrorBoundary extends React.Component<any, any> {
  state: any = {};

  constructor(props: any) {
    super(props);
    this.state = {
      error: false
    };
  }

  static getDerivedStateFromError(error: any) {
    return { error: true, message: error.message };
  }

  render() {
    return this.state.error ? (
      <div className="error-overlay" style={{ position: "relative" }}>
        <Toast type="error" message={this.state.message} />
        {this.props.children}
      </div>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
