import { type ReactNode } from "react";
import useClickAreaObserver from "../hooks/useClickAreaObserver";
import Button from "../components/io/Button";
import "../styles/components/overlay.sass";

interface Props {
  title?: string;
  isOpen?: boolean;
  isPadded?: boolean;
  size?: "small";
  close: () => void;
  children: ReactNode;
}

function Overlay(props: Props) {
  const isOpen = props.isOpen ?? true;

  const ref = useClickAreaObserver<HTMLDivElement>(props.close);

  return isOpen ? (
    <div className="overlay__background">
      <div
        className={props.size ? `overlay overlay--${props.size}` : "overlay"}
        ref={ref}
      >
        {props.title && <p className="overlay__title">{props.title}</p>}
        <div
          className={
            props.isPadded === false
              ? `overlay__body overlay__body--unpadded`
              : "overlay__body"
          }
        >
          {props.children}
        </div>
      </div>
    </div>
  ) : null;
}

type ConfirmationProps = Pick<Props, "isOpen" | "close" | "title"> & {
  target: string;
  confirmed: () => void;
};

Overlay.Confirmation = function ConfirmationOverlay(props: ConfirmationProps) {
  const title = props.title ?? `Delete ${props.target}?`;

  return (
    <Overlay
      title={title}
      isOpen={props.isOpen}
      close={props.close}
      size="small"
    >
      <p className="overlay___text confirmation-overlay-text">
        The {props.target} will be permanently deleted.
      </p>

      <Button.Group grow={true}>
        <Button
          type="danger"
          value="Delete"
          icon="trash"
          onClick={props.confirmed}
        />
        <Button
          type="blank"
          value="Cancel"
          icon="close"
          onClick={props.close}
        />
      </Button.Group>
    </Overlay>
  );
};

export default Overlay;
