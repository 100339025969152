import { createRequestClient } from "../utils";

const request = createRequestClient();

export async function login(username: string, password: string) {
  return await request.post("/sessions/new", { username, password });
}

export async function destroySession(sessionKey: string) {
  return await request.post("/sessions/destroy", null, {
    headers: {
      authorization: `Bearer ${sessionKey}`
    }
  });
}

export async function register(
  username: string,
  email: string,
  password: string,
  countryCode: string
) {
  return await request.post("/users", {
    username,
    email,
    password,
    countryCode
  });
}

export async function fetchUserByName(username: string) {
  return (await request.get(`/users?username=${username}`))[0];
}

export async function uploadAvatar(
  userId: string,
  file: File,
  sessionKey: string
) {
  const formData = new FormData();
  formData.append("avatar", file);

  return request.put(`/users/${userId}`, formData, {
    headers: {
      authorization: `Bearer ${sessionKey}`,
      "content-type": "multipart/form-data"
    }
  });
}

export async function removeAvatar(userId: string, sessionKey: string) {
  return await request.put(
    `/users/${userId}`,
    { avatar: null },
    {
      headers: {
        authorization: `Bearer ${sessionKey}`
      }
    }
  );
}

export async function fetchUser(userId: string, sessionKey?: string) {
  return await request.get(
    `/users/${userId}`,
    sessionKey
      ? {
          headers: {
            authorization: `Bearer ${sessionKey}`
          }
        }
      : {}
  );
}

export async function fetchIdentity(sessionKey: string) {
  return await request.get("/sessions/identity", {
    headers: {
      authorization: `Bearer ${sessionKey}`
    }
  });
}
