import { SET_USER, SET_AVATAR_ID, SET_TOAST } from "../actions";

function reduce(state = {}, action: { type: string; payload: any }) {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_AVATAR_ID:
      return { ...state, avatarId: action.payload };
    case SET_TOAST:
      return {
        ...state,
        toast: action.payload
      };
    default:
      return state;
  }
}

export default reduce;
