interface Props {
  type?: "error" | "ok";
  message: string;
}

function Toast(props: Props) {
  return <div className={
    `toast${
      props.type ? ` toast-${props.type}` : ""
    }${
      props.type ? " toast-active" : ""
    }`
 }>{props.message}</div>;
}

export default Toast;
