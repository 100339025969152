import { useEffect } from "react";

const baseTitle = "Glomfig";

function useTitle(title?: string) {
  useEffect(() => {
    document.title = title ? `${title} | ${baseTitle}` : baseTitle;
  });
}

export default useTitle;
