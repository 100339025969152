import {
  useEffect,
  useState,
  useRef,
  type ReactNode,
  type ChangeEvent
} from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Encoding from "encoding-japanese";

import { getSessionKey, simulateDelay, setToast, getGameTitle } from "../utils";
import {
  addConfigResource,
  fetchConfig,
  saveResource,
  fetchConfigResourceData,
  updateConfig,
  updateResource,
  deleteConfig,
  deleteResource
} from "../services/ConfigService";
import { fetchUser } from "../services/UserService";
import useTitle from "../hooks/useTitle";
import Button from "../components/io/Button";
import Overlay from "../components/Overlay";
import Icon from "../components/Icon";
import TextEditor from "../components/TextEditor";
import gameTitles from "../assets/game-titles.json";

import "../styles/views/config-view.sass";
import "../styles/components/login-box.sass";

interface Props {
  identity?: User;
}

interface HeaderProps {
  config: Config;
  ownerName?: string;
  isMutable: boolean;
  openOverlay: (type: OverlayType) => void;
  openFileBrowser?: () => void;
}

ConfigView.Header = function Header(props: HeaderProps) {
  return (
    <section className="section section--small config-view-section">
      <div className="container container-xxl config-view-header">
        <div className="config-view-badges">
          <p className="config-view-badge">
            {getGameTitle(props.config.game as GameID)}
          </p>
          {props.config.isPrivate && (
            <p className="config-view-badge">Private</p>
          )}
        </div>

        <div className="config-view-header-row">
          <div className="config-view-header-info">
            <p className="config-name">{props.config.name}</p>
            <p className="config-owner">
              {props.config.owner && (
                <span className="config-owner">
                  by{" "}
                  <Link to={`/users/${props.ownerName}`}>
                    {props.ownerName}
                  </Link>
                </span>
              )}
            </p>
          </div>

          {props.isMutable && (
            <div className="config-view-header-buttons">
              <Button.Group>
                <Button
                  value="Upload"
                  type="blank"
                  icon="add"
                  onClick={props.openFileBrowser}
                />
                <Button
                  value="Settings"
                  type="blank"
                  icon="gears"
                  onClick={() => props.openOverlay("config-settings")}
                />
                <Button
                  value="Delete"
                  type="blank"
                  icon="trash"
                  onClick={() => props.openOverlay("config-delete")}
                />
              </Button.Group>
            </div>
          )}
        </div>

        <div className="config-view-header-row config-view-header-row">
          <p
            className={`config-view-description${
              props.config.description ? "" : " italic"
            }`}
          >
            {props.config.description || "No description provided"}
          </p>
        </div>
      </div>
    </section>
  );
};

ConfigView.Editor = function Editor(props: {
  configId: string;
  resources?: Resource[];
  isMutable: boolean;
  setConfig: CallableFunction;
}) {
  const [selectedResource, setSelectedResource] = useState<Resource | null>(
    null
  );
  const [textContent, setTextContent] = useState<string | null>(null);
  const [overlay, setOverlay] = useState<
    "new" | "properties" | "delete" | null
  >(null);
  const [deletionTarget, setDeletionTarget] = useState<string | null>(null);

  async function selectResource(resource: Resource) {
    setSelectedResource(resource);
    try {
      const data = await fetchConfigResourceData(
        props.configId,
        resource._id,
        getSessionKey() as string
      );
      setTextContent(data.data);
    } catch (err) {
      setToast("error", "Unable to fetch file");
    }
  }

  function editorContentChanged(content: string | null) {
    setTextContent(content);
  }

  async function deleteRequested(id: string) {
    setDeletionTarget(id);
    setOverlay("delete");
  }

  async function addResource(values: any) {
    try {
      props.setConfig(
        await addConfigResource(
          props.configId,
          values.name,
          getSessionKey() as string
        )
      );
      setToast("ok", "Resoure added");
      closeOverlay();
    } catch {
      setToast("error", "Unable to add file");
    }
  }

  async function saveCurrentResource() {
    if (selectedResource) {
      try {
        await saveResource(
          props.configId,
          selectedResource._id,
          textContent,
          getSessionKey() as string
        );
        setToast("ok", "File saved");
      } catch (err) {
        setToast("error", "Unable to save file");
      }
    }
  }

  async function updateCurrentResourceProperties(values: any) {
    if (selectedResource) {
      try {
        props.setConfig(
          await updateResource(
            props.configId,
            selectedResource._id,
            values,
            getSessionKey() as string
          )
        );
        closeOverlay();
        setToast("ok", "File properties updated");
      } catch {
        setToast("error", "Unable to update file properties");
      }
    }
  }

  async function handleResourceDeletion() {
    if (deletionTarget) {
      try {
        props.setConfig(
          await deleteResource(
            props.configId,
            deletionTarget,
            getSessionKey() as string
          )
        );
        setToast("ok", "File successfully deleted");
      } catch {
        setToast("error", "Unable to delete file");
      } finally {
        setDeletionTarget(null);
        closeOverlay();
      }
    }
  }

  function closeOverlay() {
    setOverlay(null);
  }

  async function copyToClipboard() {
    if (textContent) {
      await navigator.clipboard.writeText(textContent);
      setToast("ok", "Copied to clipboard");
    }
  }

  function drawOverlay() {
    let title: string | undefined = undefined;
    let body: ReactNode | null = null;

    switch (overlay) {
      case "new":
        title = "New file";
        break;
      case "properties":
        title = "File properties";
        break;
      case "delete":
        return (
          <Overlay.Confirmation
            target="resource"
            confirmed={handleResourceDeletion}
            isOpen={overlay !== null}
            close={closeOverlay}
          />
        );
    }

    body = (
      <>
        <Formik
          initialValues={{
            name: overlay === "properties" ? selectedResource?.name : ""
          }}
          onSubmit={
            overlay === "properties"
              ? updateCurrentResourceProperties
              : addResource
          }
        >
          <Form className="form">
            <div className="form__item form__item--vertical form__item--grow">
              <label htmlFor="resource-name" className="form__label">
                Filename
              </label>
              <Field
                type="text"
                id="resource-name"
                name="name"
                placeholder="Filename"
                className="form__field"
              />
            </div>

            <div className="form__track form__track--responsive-xs">
              <div className="form__item form__item--grow">
                <Button
                  as="submit"
                  type="ok"
                  value={overlay === "properties" ? "Save" : "Create"}
                  icon={overlay === "properties" ? "save" : "add"}
                  className="flex-fill"
                />
              </div>
              <div className="form__item form__item--grow">
                <Button
                  type="blank"
                  value="Cancel"
                  icon="close"
                  onClick={closeOverlay}
                  className="flex-fill"
                />
              </div>
            </div>
          </Form>
        </Formik>
      </>
    );

    return (
      <Overlay isOpen={overlay !== null} title={title} close={closeOverlay}>
        {body}
      </Overlay>
    );
  }

  return (
    <section className="section">
      <div className="container container-xxl">
        {drawOverlay()}
        {!props.resources ? (
          <p>Loading</p>
        ) : props.resources.length === 0 ? (
          <div className="config-view-editor-empty-container">
            <div className="config-view-editor-empty">
              <p>No resources exist.</p>
              {props.isMutable && (
                <Button
                  value="Create file"
                  icon="add"
                  type="ok"
                  onClick={() => setOverlay("new")}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="config-view-editor-resources-container">
            <ul className="config-view-editor-resources">
              <div className="config-view-editor-resources-header">
                <p className="config-view-editor-resources-title">Files</p>
                {props.isMutable && (
                  <button
                    className="editor-button"
                    onClick={() => setOverlay("new")}
                  >
                    <Icon name="add" size="0.75rem" />
                  </button>
                )}
              </div>
              {props.resources.map((resource) => (
                <li
                  key={resource._id}
                  className={
                    selectedResource?._id === resource._id
                      ? "resource-header resource-header-active"
                      : "resource-header"
                  }
                >
                  <Icon name="gears" size="1rem" />
                  <p onClick={selectResource.bind(null, resource)}>
                    {resource.name}
                  </p>

                  {
                    props.isMutable &&
                      <button
                        className="editor-button resource-delete-button"
                        onClick={() => deleteRequested(resource._id)}
                      >
                        <Icon name="trash" size="0.75rem" />
                      </button>
                  }
                </li>
              ))}
            </ul>

            {selectedResource ? (
              <div className="config-view-content">
                <div className="config-view-content-controls">
                  <p className="config-view-filename">
                    {selectedResource.name}
                  </p>
                  <div className="config-view-editor-buttons">
                    <Button.Group>
                      {props.isMutable && (
                        <Button
                          value="Options"
                          type="blank"
                          icon="edit"
                          onClick={() => setOverlay("properties")}
                        />
                      )}

                      <Button
                        value="Copy"
                        type="blank"
                        icon="copy"
                        onClick={copyToClipboard}
                      />

                      {props.isMutable && (
                        <Button
                          value="Save"
                          type="ok"
                          icon="save"
                          onClick={saveCurrentResource}
                        />
                      )}
                    </Button.Group>
                  </div>
                </div>
                {props.isMutable ? (
                  <TextEditor
                    tabSize={2}
                    wrap={true}
                    showMargin={false}
                    className="config-editor"
                    content={textContent ?? ""}
                    contentChanged={editorContentChanged}
                  />
                ) : (
                  <div className="config-editor config-preview">
                    <pre>{textContent ?? ""}</pre>
                  </div>
                )}
              </div>
            ) : (
              <div className="no-open-resource-message">
                <p>
                  Open a file to{" "}
                  {props.isMutable ? "start editing" : "preview it"}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

type OverlayType = "config-delete" | "config-settings";

function ConfigView(props: Props): JSX.Element | null {
  const [config, setConfig] = useState<Config | null>(null);
  const [ownerName, setOwnerName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [overlay, setOverlay] = useState<OverlayType | null>(null);
  const inputElementRef = useRef<HTMLInputElement>(null);

  useTitle(config?.name ?? undefined);

  const navigate = useNavigate();
  const location = useLocation();

  const targetId = location.pathname.split("/").pop();

  async function loadConfig() {
    if (targetId) {
      try {
        const config = await simulateDelay(
          fetchConfig(targetId, getSessionKey() ?? undefined),
          500
        );

        setOwnerName((await fetchUser(config.owner)).username);

        if (config) {
          setConfig(config);
        }
      } catch (err) {
        if ((err as any).message.startsWith("403")) {
          navigate("/login");
          setToast("error", "Config is private");
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function saveSettings(values: any, { setSubmitting }: any) {
    if (!config) {
      return;
    }

    if (values.gameId) {
      values.game = values.gameId;
      Reflect.deleteProperty(values, "gameId");
    } else if (values.gameId === "") {
      values.game = null;
      Reflect.deleteProperty(values, "gameId");
    }

    if (values.visibility) {
      if (values.visibility === "public") {
        values.isPrivate = false;
      } else if (values.visibility === "private") {
        values.isPrivate = true;
      }

      Reflect.deleteProperty(values, "visibility");
    }

    try {
      setConfig(
        await updateConfig(config.id, values, getSessionKey() as string)
      );
      setToast("ok", "Config successfully updated");
    } catch {
      setToast("error", "Failed to update config");
    }
  }

  async function handleConfigDeletion() {
    if (config) {
      try {
        await deleteConfig(config.id, getSessionKey() as string);
        navigate("/");
        setToast("ok", "Config successfully deleted");
      } catch {
        setToast("error", "Failed to deleted config");
      }
    }
  }

  function openOverlay(type: OverlayType) {
    setOverlay(type);
  }

  function closeOverlay() {
    setOverlay(null);
  }

  function drawOverlay() {
    switch (overlay) {
      case "config-delete":
        return (
          <Overlay.Confirmation
            target="config"
            confirmed={handleConfigDeletion}
            isOpen={overlay !== null}
            close={closeOverlay}
          />
        );
      case "config-settings":
        return (
          <Overlay
            isOpen={overlay !== null}
            title="Config settings"
            close={closeOverlay}
          >
            <Formik
              initialValues={{
                name: config?.name,
                description: config?.description,
                visibility: config?.isPrivate ? "private" : "public",
                gameId: config?.game
              }}
              onSubmit={saveSettings}
            >
              <Form className="form">
                <div className="form__item form__item--vertical">
                  <label htmlFor="name" className="form__label">
                    Name
                  </label>
                  <Field
                    name="name"
                    type="text"
                    placeholder="Name"
                    className="form__field"
                  />
                </div>

                <div className="form__item form__item--vertical">
                  <label htmlFor="description" className="form__label">
                    Description
                  </label>
                  <Field
                    type="text"
                    as="textarea"
                    name="description"
                    placeholder="Description"
                    className="form__field form__field--textarea config-view-description-edit"
                  />
                </div>

                <div className="form__track form__track--responsive-sm">
                  <div className="form__item form__item--vertical form__item--grow">
                    <label htmlFor="gameId" className="form__label">
                      Game
                    </label>

                    <Field
                      name="gameId"
                      id="gameId"
                      as="select"
                      className="form__select"
                    >
                      <option value="" label="Other" />
                      {Object.entries(gameTitles).map(([key, value]) => (
                        <option key={key} value={key} label={value} />
                      ))}
                    </Field>
                  </div>

                  <div className="form__item form__item--vertical form__item--grow">
                    <label htmlFor="visibility" className="form__label">
                      Visibility
                    </label>

                    <Field
                      name="visibility"
                      id="visibility"
                      as="select"
                      className="form__select"
                    >
                      <option value="public" label="Public" />
                      <option value="private" label="Private" />
                    </Field>
                  </div>
                </div>

                <div className="form__track form__track--responsive-xs">
                  <div className="form__item form__item--grow">
                    <Button
                      as="submit"
                      type="ok"
                      value="Save"
                      icon="save"
                      className="flex-fill"
                    />
                  </div>
                  <div className="form__item form__item--grow">
                    <Button
                      type="blank"
                      value="Close"
                      icon="close"
                      onClick={closeOverlay}
                      className="flex-fill"
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </Overlay>
        );
    }
  }

  async function filesChanged(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files && config) {
      try {
        await Promise.all(
          Array.prototype.map.call(e.target.files, async (file) => {
            const textContent = await file.text();

            if (Encoding.detect(textContent) !== "ASCII") {
              throw new Error("Unsupported format");
            }

            return addConfigResource(
              config.id,
              file.name,
              getSessionKey() as string,
              await file.text()
            );
          })
        );

        setConfig(await fetchConfig(config.id, getSessionKey() as string));
        setToast("ok", "Files successfully uploaded");
      } catch (err) {
        setToast(
          "error",
          `Failed to upload file: ${(err as any).message}`
        );
      }
    }
  }

  useEffect(() => {
    loadConfig();
  }, [targetId, config?.childResources?.length]);

  return (
    <main className="config-view">
      {drawOverlay()}
      {config ? (
        <>
          <ConfigView.Header
            config={config}
            ownerName={ownerName ?? undefined}
            isMutable={props.identity?.id === config.owner}
            openOverlay={openOverlay}
            openFileBrowser={() => inputElementRef.current?.click()}
          />
          <ConfigView.Editor
            configId={config.id}
            resources={config.childResources}
            isMutable={props.identity?.id === config.owner}
            setConfig={setConfig}
          />
        </>
      ) : isLoading ? (
        <div className="config-list-loading">
          <p>Loading</p>

          <div className="configs-loading-icon">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      ) : (
        <div className="config-list-loading">
          <p>No such config exists</p>
        </div>
      )}

      <input
        ref={inputElementRef}
        name="file"
        type="file"
        multiple={true}
        onChange={filesChanged}
        style={{ display: "none" }}
      />
    </main>
  );
}

function mapStateToProps(state: any) {
  return { identity: state.user };
}

export default connect(mapStateToProps, null)(ConfigView);
