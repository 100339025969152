import { Link } from "react-router-dom";
import "../styles/components/config-box.sass";
import { getGameTitle } from "../utils";

interface Props {
  config: Config;
}

function ConfigBox(props: Props) {
  const { config } = props;

  return (
    <Link to={`/configs/${config.id}`} className="config-box">
      <div className="config-box-edge config-box-link">
        <div className="config-box-name">{config.name}</div>
      </div>

      <div className="config-box-main">
        <p
          className={`config-box-description${
            config.description ? "" : " italic"
          }`}
        >
          {config.description || "No description provided"}
        </p>
      </div>

      <div className="config-box-edge">
        <p className="config-box-game">{getGameTitle(config.game as GameID)}</p>
      </div>
    </Link>
  );
}

export default ConfigBox;
