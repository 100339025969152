import store from "../stores";

export const SET_USER = "set__user";
export const SET_AVATAR_ID = "set__avatar-id";
export const SET_TOAST = "set__toast";

export const setToast = (type: "error" | "ok", message: string, id: symbol) =>
  store.dispatch({ type: SET_TOAST, payload: { type, message, id } });

export const clearToast = () =>
  store.dispatch({ type: SET_TOAST, payload: null });

export const setUser = (user: User | null) =>
  store.dispatch({ type: SET_USER, payload: user });

export const setAvatar = (avatarId: string | null) =>
  store.dispatch({ type: SET_AVATAR_ID, payload: avatarId });
