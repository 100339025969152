interface EnvVariable {
  fallback: string;
  development?: string;
  testing?: string;
}

function envVariable(envVariable: EnvVariable): string {
  const { fallback, development, testing } = envVariable;

  switch (process.env.NODE_ENV) {
    case "development":
      return development ?? fallback;
    case "testing":
      return testing ?? fallback;
    default:
      return fallback;
  }
}

export const API_HOST = envVariable({
  development: "http://localhost:8080",
  fallback: "https://api.glomfig.com"
});

export const IMAGE_HOST = envVariable({
  development: "http://localhost:6789",
  fallback: "https://i.glomfig.com"
});
