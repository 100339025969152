import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { destroySession } from "../services/UserService";
import {
  getSessionKey,
  clearSessionKey,
  getAvatarUrl,
  setToast
} from "../utils";
import { setUser } from "../actions";
import useClickAreaObserver from "../hooks/useClickAreaObserver";
import { LoginForm, handleLogin } from "../views/Login";
import Icon from "../components/Icon";
import Overlay from "../components/Overlay";
import Button from "../components/io/Button";

import "../styles/components/header.sass";

interface Props {
  user?: User;
  isOverlayOpen?: boolean;
  openOverlay: () => void;
  closeOverlay: () => void;
}

function Header(props: Props): JSX.Element {
  const [menuOpen, setMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const menuRef = useClickAreaObserver<HTMLElement>(closeMenu);

  useEffect(() => setMenuOpen(false), [location.pathname]);

  async function handleFormSubmit(values: {
    username: string;
    password: string;
  }) {
    if (await handleLogin(values.username, values.password)) {
      closePopup();
    }
  }

  async function logout() {
    try {
      await destroySession(getSessionKey() as string);
      setToast("ok", "Logged out successfully");
      setUser(null);
      clearSessionKey();
    } catch {
      setToast("error", "Unable to log out");
    } finally {
      closeMenu();
    }
  }

  function toggleMenu() {
    setMenuOpen((isOpen) => !isOpen);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function closePopup() {
    setOpen(false);
  }

  function openLogin() {
    setMenuOpen(false);
    setOpen(true);
  }

  function openContact() {
    setMenuOpen(false);
    props.openOverlay();
  }

  const avatarSrc = props.user
    ? getAvatarUrl(props.user) ??
      /* eslint-disable */
      require("../assets/default-avatar.svg").default
    : /* eslint-enable */
      undefined;

  return (
    <header className="section section--small header">
      <div className="container container-fluid header-container">
        <div className="header-section">
          {open && (
            <Overlay
              title="Log in"
              isOpen={open}
              close={closePopup}
              size="small"
            >
              <LoginForm handleSubmit={handleFormSubmit} />
            </Overlay>
          )}
          <Link to="/" className="header-logo">
            Glomfig<span className="color-accent">.com</span>
          </Link>
        </div>

        <div className="header-section">
          <nav
            className={`main-menu${menuOpen ? " main-menu-open" : ""}`}
            ref={menuRef}
          >
            <div className="main-menu-section">
              <div className="main-menu-section-title">Account</div>
              {props.user ? (
                <div className="main-menu-section-content">
                  <Link
                    className="main-menu-link"
                    to={`/users/${props.user.username}`}
                    onClick={closeMenu}
                  >
                    Profile
                  </Link>
                  <Link
                    className="main-menu-link"
                    to="/new"
                    onClick={closeMenu}
                  >
                    Create config
                  </Link>
                </div>
              ) : (
                <div className="main-menu-section-content">
                  <div className="main-menu-link" onClick={openLogin}>
                    Log in
                  </div>
                  <Link
                    className="main-menu-link"
                    onClick={closeMenu}
                    to="/register"
                  >
                    Sign up
                  </Link>
                </div>
              )}
            </div>
            <div className="main-menu-dropdown">
              <div className="main-menu-section">
                <div className="main-menu-section-close" onClick={closeMenu}>
                  <Icon name="close" size="1rem" />
                </div>
                <div className="main-menu-section-title">Configuration</div>
                <div className="main-menu-section-content">
                  <Link className="main-menu-link" onClick={closeMenu} to="/explore">
                    Explore
                  </Link>
                  <Link className="main-menu-link" onClick={closeMenu} to="/explore/recent">
                    Recent
                  </Link>
                  <Link className="main-menu-link" onClick={closeMenu} to="/explore/popular">
                    Popular
                  </Link>
                </div>
              </div>

              <div className="main-menu-section">
                <p className="main-menu-section-title">Network</p>

                <div className="main-menu-section-content">
                  <button className="main-menu-link" onClick={openContact}>
                    Support
                  </button>
                  <button className="main-menu-link" onClick={openContact}>
                    Contact
                  </button>
                  <Link className="main-menu-link" onClick={closeMenu} to="/help">
                    Help
                  </Link>
                </div>
              </div>
              {props.user && (
                <div className="main-menu-section main-menu-section-bottom">
                  <Link
                    to={`/users/${props.user.username}`}
                    className="main-menu-profile-link"
                  >
                    <div className="profile-picture profile-picture-small">
                      <img src={avatarSrc} />
                    </div>
                    {props.user.username}
                  </Link>

                  <Button value="Log out" onClick={logout} />
                </div>
              )}
            </div>
          </nav>

          <div className="main-menu-toggle-button" onClick={toggleMenu}>
            <Icon name="menu" />
          </div>
        </div>
      </div>
    </header>
  );
}

function mapStateToProps(state: any) {
  return { user: state.user };
}

export default connect(mapStateToProps, null)(Header);
