import { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import useTitle from "../hooks/useTitle";
import "../styles/views/policy.sass";

function Policy() {
  useTitle("Privacy policy");

  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    loadContent();
  }, []);

  async function loadContent() {
    const response = await fetch(
      (
        await import("../assets/privacy-policy.md")
      ).default
    );
    setContent(await response.text());
  }

  return (
    <section className="page section">
      <div className="container container-xl">
        {content ? (
          <ReactMarkdown className="privacy-policy">{content}</ReactMarkdown>
        ) : (
          <p>Loading</p>
        )}
      </div>
    </section>
  );
}

export default Policy;
