import icons from "../assets/icons.json";

interface Icon {
  viewBox: string;
  d: string;
}

declare global {
  type IconName = keyof typeof icons;
}

interface Props {
  name: IconName;
  size?: string;
  className?: string;
  fill?: string;
}

function Icon(props: Props) {
  const icon = icons[props.name] as Icon;
  const size = props.size ?? 24;
  const viewBox = icon.viewBox;
  const d = icon.d;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={viewBox}
      fill={props.fill}
    >
      <path d={d} />
    </svg>
  );
}

export default Icon;
