import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { useState } from "react";

import store from "../stores";
import { getSessionKey, clearSessionKey, setToast } from "../utils";
import { fetchIdentity } from "../services/UserService";
import { setUser } from "../actions";

import HomeView from "./Home";
import RegisterView from "./Register";
import LoginView from "./Login";
import ProfileView from "./Profile";
import ConfigCreator from "./ConfigCreator";
import ConfigView from "./ConfigView";
import Policy from "./Policy";
import Faq from "./Faq";
import Explore from "./Explore";

import Header from "../components/Header";
import Footer from "../components/Footer";
import ErrorBoundary from "../components/ErrorBoundary";
import Toast from "../components/Toast";

async function initializeSession() {
  const sessionKey = getSessionKey();

  if (sessionKey) {
    const identity = await fetchIdentity(sessionKey);

    if (identity) {
      setUser(identity);
    } else {
      clearSessionKey();
      setToast("error", "Invalid or expired session");
    }
  }
}

initializeSession();

function Toaster() {
  const toast = useSelector<Store>((state) => state.toast) as Toast;
  return <Toast type={toast?.type} message={toast?.message} />;
}

function App(): JSX.Element {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  function openOverlay() {
    setIsOverlayOpen(true);
  }

  function closeOverlay() {
    setIsOverlayOpen(false);
  }

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Toaster />
        <Router>
          <Header isOverlayOpen={isOverlayOpen} openOverlay={openOverlay} closeOverlay={closeOverlay}/>
          <div className="page__content">
            <Routes>
              <Route path="/" element={<HomeView />} />
              <Route path="/users/:username" element={<ProfileView />} />
              <Route path="/login" element={<LoginView />} />
              <Route path="/register" element={<RegisterView />} />
              <Route path="/new" element={<ConfigCreator />} />
              <Route path="/configs/:id" element={<ConfigView />} />
              <Route path="/privacy" element={<Policy />} />
              <Route path="/help" element={<Faq />} />
              <Route path="/explore/:type?" element={<Explore />} />
            </Routes>
          </div>
          <Footer isOverlayOpen={isOverlayOpen} openOverlay={openOverlay} closeOverlay={closeOverlay}/>
        </Router>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
