import { createRequestClient, buildQueryString } from "../utils";

const request = createRequestClient("/");

export async function createConfig(
  config: Omit<Config, "id" | "owner" | "createdAt">,
  sessionKey: string
) {
  return await request.post("/configs", config, {
    headers: {
      authorization: `Bearer ${sessionKey}`
    }
  });
}

export async function addConfigResource(
  configId: string,
  name: string,
  sessionKey: string,
  data?: string
) {
  return await request.post(
    `/configs/${configId}/resources`,
    { name, data },
    {
      headers: {
        authorization: `Bearer ${sessionKey}`
      }
    }
  );
}

export async function fetchConfigs(
  limit?: number,
  sort?: "desc",
  types?: string[],
  sessionKey?: string
) {

  return await request.get(
    "/configs" + buildQueryString({ limit, sort, type: types }),
    sessionKey
      ? {
          headers: {
            authorization: `Bearer ${sessionKey}`
          }
        }
      : {}
  );
}

export async function fetchConfig(configId: string, sessionKey?: string) {
  return await request.get(
    `/configs/${configId}`,
    sessionKey
      ? {
          headers: {
            authorization: `Bearer ${sessionKey}`
          }
        }
      : {}
  );
}

export async function fetchConfigResourceData(
  configId: string,
  resourceId: string,
  sessionKey?: string
) {
  return await request.get(
    `/configs/${configId}/resources/${resourceId}/data`,
    sessionKey
      ? {
          headers: {
            authorization: `Bearer ${sessionKey}`
          }
        }
      : {}
  );
}

export async function fetchConfigResources(
  configId: string,
  resourceId: string,
  sessionKey?: string
) {
  return await request.get(
    `/configs/${configId}/resources/${resourceId}`,
    sessionKey
      ? {
          headers: {
            authorization: `Bearer ${sessionKey}`
          }
        }
      : {}
  );
}

export async function fetchUserConfigs(
  userId: string,
  sessionKey: string | null
) {
  const options = sessionKey
    ? {
        headers: {
          authorization: `Bearer ${sessionKey}`
        }
      }
    : {};

  return await request.get(`/users/${userId}/configs`, options);
}

export async function saveResource(
  configId: string,
  resourceId: string,
  data: string | null,
  sessionKey: string
) {
  return await request.put(
    `/configs/${configId}/resources/${resourceId}`,
    { data },
    {
      headers: {
        authorization: `Bearer ${sessionKey}`
      }
    }
  );
}

export async function updateResource(
  configId: string,
  resourceId: string,
  updatedFields: any,
  sessionKey: string
) {
  return await request.put(
    `/configs/${configId}/resources/${resourceId}`,
    updatedFields,
    {
      headers: {
        authorization: `Bearer ${sessionKey}`
      }
    }
  );
}

export async function updateConfig(
  configId: string,
  config: Partial<Omit<Config, "id" | "owner" | "childResources">>,
  sessionKey: string
) {
  return await request.put(`/configs/${configId}`, config, {
    headers: {
      authorization: `Bearer ${sessionKey}`
    }
  });
}

export async function deleteConfig(configId: string, sessionKey: string) {
  return await request.delete(`/configs/${configId}`, {
    headers: {
      authorization: `Bearer ${sessionKey}`
    }
  });
}

export async function deleteResource(
  configId: string,
  resourceId: string,
  sessionKey: string
) {
  return await request.delete(`/configs/${configId}/resources/${resourceId}`, {
    headers: {
      authorization: `Bearer ${sessionKey}`
    }
  });
}
