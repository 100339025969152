import { useState, useRef, type ChangeEvent } from "react";
import { setUser } from "../actions";
import { getSessionKey, simulateDelay, setToast } from "../utils";
import Icon from "../components/Icon";
import { uploadAvatar, removeAvatar } from "../services/UserService";

interface Props {
  src: string | null;
  userId: string | null;
  isMutable: boolean;
  isSmall?: boolean;
}

function Avatar(props: Props) {
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputElementRef = useRef<HTMLInputElement>(null);

  const fileChanged = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const browseFiles = () => {
    inputElementRef.current?.click();
  };

  const clearPreview = () => {
    setSelectedFile(null);

    if (inputElementRef.current) {
      inputElementRef.current.value = "";
    }
  };

  async function remove() {
    if (!props.userId) {
      return;
    }

    setUser(await removeAvatar(props.userId, getSessionKey() as string));
  }

  async function upload() {
    if (!props.userId) {
      return;
    }

    if (selectedFile) {
      setUploading(true);

      try {
        setUser(
          await simulateDelay(
            uploadAvatar(props.userId, selectedFile, getSessionKey() as string),
            1000
          )
        );

        setToast("ok", "Avatar successfully uploaded");
      } catch (err) {
        setToast("error", (err as any).message);
      } finally {
        setSelectedFile(null);
        setUploading(false);
      }
    }
  }

  const imageSource = selectedFile
    ? URL.createObjectURL(selectedFile)
    : /* eslint-disable */
      props.src ?? require("../assets/default-avatar.svg").default;
  /* eslint-enable */

  return (
    <div className={`profile-picture ${props.isSmall ?
      "profile-picture-extra-small" : ""}`}>
      <img src={imageSource} />
      {uploading && (
        <div className="avatar-loading-icon">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}

      {props.isMutable && !uploading && (
        <div
          className={`profile-picture-actions ${selectedFile ? "active" : ""}`}
        >
          <button
            onClick={selectedFile ? clearPreview : browseFiles}
            className="profile-picture-action-button"
          >
            <Icon
              name={!selectedFile ? (props.src ? "edit" : "add") : "undo"}
            />
          </button>

          {(props.src || selectedFile) && (
            <button
              onClick={!selectedFile ? remove : upload}
              className="profile-picture-action-button"
            >
              <Icon name={!selectedFile ? "trash" : "save"} />
            </button>
          )}
        </div>
      )}

      <input
        ref={inputElementRef}
        name="file"
        type="file"
        onChange={fileChanged}
        style={{ display: "none" }}
      />
    </div>
  );
}

export default Avatar;
