import { type ReactNode } from "react";
import Icon from "../Icon";
import "../../styles/components/io/button.sass";

type Button = "regular" | "blank" | "ok" | "danger";

interface Props {
  as?: "submit" | "button" | "reset";
  type?: Button;
  value: string;
  onClick?: () => void;
  icon?: IconName;
  className?: string;
}

interface ButtonGroupProps {
  children: ReactNode;
  grow?: boolean;
}

Button.Group = function ButtonGroup(props: ButtonGroupProps) {
  const classes = ["button__group"];

  if (props.grow) {
    classes.push("button__group--grow");
  }

  return <div className={classes.join(" ")}>{props.children}</div>;
};

function Button(props: Props) {
  const type = props.type ?? "regular";

  const classes = ["button", `button--${type}`];

  if (props.className) {
    classes.push(props.className);
  }

  if (props.icon) {
    classes.push("button--icon");
  }

  return (
    <button
      type={props.as ?? "button"}
      onClick={props.onClick}
      className={classes.join(" ")}
    >
      {props.icon && <Icon name={props.icon} />}
      <p>{props.value}</p>
    </button>
  );
}

export default Button;
