import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import { setToast, getCountryNames } from "../utils";
import { register } from "../services/UserService";
import useTitle from "../hooks/useTitle";
import Button from "../components/io/Button";

import "../styles/views/register.sass";

function Register(): JSX.Element {
  useTitle("Sign up");

  const navigate = useNavigate();

  async function handleRegistration(values: {
    username: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    countryCode: string;
  }) {
    if (values.password !== values.passwordConfirmation) {
      setToast("error", "Passwords do not match");
    } else {
      try {
        const { username } = await register(
          values.username,
          values.email,
          values.password,
          values.countryCode
        );
        setToast("ok", "Account successfully created");

        navigate(`/users/${username}`);

      } catch {
        setToast("error", "Account creation failed");
      }
    }
  }

  return (
    <section className="section">
      <div className="container container-xs">
        <h1 className="registration-title">Create a new account</h1>
        <Formik
          initialValues={{
            username: "",
            email: "",
            password: "",
            passwordConfirmation: "",
            countryCode: ""
          }}
          onSubmit={handleRegistration}
        >
          <Form className="form registration-form">
            <div className="form__item form__item--vertical form__item--grow">
              <label htmlFor="username" className="form__label">
                Username
              </label>
              <Field
                name="username"
                type="text"
                placeholder="Username"
                className="form__field"
              />
            </div>

            <div className="form__item form__item--vertical form__item--grow">
              <label htmlFor="email" className="form__label">
                Email
              </label>
              <Field
                name="email"
                type="email"
                placeholder="Email"
                className="form__field"
              />
            </div>

            <div className="form__item form__item--vertical form__item--grow">
              <label htmlFor="password" className="form__label">
                Password
              </label>
              <Field
                name="password"
                type="password"
                placeholder="Password"
                className="form__field"
              />
            </div>

            <div className="form__item form__item--vertical form__item--grow">
              <label htmlFor="passwordConfirmation" className="form__label">
                Confirm password
              </label>
              <Field
                name="passwordConfirmation"
                type="password"
                placeholder="Confirm password"
                className="form__field"
              />
            </div>

            <div className="form__item form__item--vertical form__item--grow">
              <label htmlFor="countryCode" className="form__label">
                Country
              </label>

              <Field
                name="countryCode"
                id="countryCode"
                as="select"
                className="form__select"
              >
                <option value="" disabled hidden>
                  Select country
                </option>
                {[...(Object.entries(getCountryNames()) as any)]
                  .sort((a: string, b: string) => a[1] > b[1] ? 1 : -1)
                  .map((p: any) => (
                  <option key={p[1]} value={p[0]} label={p[1]} />
                ))}
              </Field>
            </div>

            <div className="form__item form__item--grow">
              <Button
                as="submit"
                type="ok"
                value="Create account"
                className="flex-fill"
              />
            </div>

            <div className="form__item form__item--grow">
              <p className="registration-form-text flex-fill">
                Already have an account?{" "}
                <Link to="/login" className="sign-in-link">
                  Sign in
                </Link>
              </p>
            </div>
          </Form>
        </Formik>
      </div>
    </section>
  );
}

export default Register;
