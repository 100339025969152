import "../styles/components/type-badge.sass";

interface Props {
  label: string;
  gameId: GameID;
  selected: boolean;
  onClick: () => void;
}

function TypeBadge(props: Props) {

  return (
    <button
      onClick={props.onClick}
      className={`type-badge${props.selected ? " type-badge-selected" : ""}`}
    >
      {props.label}
    </button>
  );
}

export default TypeBadge;
