import { createStore } from "redux";
import reducer from "../reducers";

const initialState: Store = {
  user: null,
  avatarId: null,
  toast: null
};

const store = createStore(reducer, initialState);

export default store;
