import { useState, useRef } from "react";
import AceEditor, { type IAceOptions } from "react-ace";
import Icon from "./Icon";
import "ace-builds/src-noconflict/mode-plain_text";

interface Props {
  tabSize?: number;
  wrap?: boolean;
  showMargin?: boolean;
  className?: string;
  content: string;
  contentChanged: (content: string | null) => void;
}

interface Cursor {
  row: number;
  column: number;
}

function TextEditor(props: Props): JSX.Element {
  const editorRef = useRef<AceEditor>(null);
  const [cursor, setCursor] = useState<Cursor | null>(null);
  const options: IAceOptions = {
    vScrollBarAlwaysVisible: false,
    hScrollBarAlwaysVisible: false,
    customScrollbar: true,
    displayIndentGuides: false
  };

  const contentChanged = () => {
    props.contentChanged(editorRef.current?.editor.session.getValue() ?? null);
  };

  const cursorChanged = () => {
    const cursorLocation = editorRef.current?.editor.getCursorPosition();
    setCursor(
      cursorLocation
        ? { row: cursorLocation.row + 1, column: cursorLocation.column + 1 }
        : null
    );
  };

  return (
    <div className={`text-editor ${props.className}`}>
      <div className="text-editor-content">
        <AceEditor
          ref={editorRef}
          mode="plain_text"
          width="100%"
          height="100%"
          tabSize={props.tabSize}
          wrapEnabled={props.wrap}
          showPrintMargin={props.showMargin}
          setOptions={options}
          scrollMargin={[16, 16]}
          onChange={contentChanged}
          onCursorChange={cursorChanged}
          value={props.content}
        />
      </div>

      <div className="text-editor-footer">
        <p className="text-editor-footer-item">
          Lines:{" "}
          {editorRef.current?.editor.session.doc.getAllLines().length ?? 1}
        </p>

        <div className="text-editor-status">
          <p className="text-editor-footer-item">
            Line {cursor?.row ?? 0}, Column {cursor?.column ?? 0}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TextEditor;
