import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";

import { getSessionKey, setToast } from "../utils";
import { createConfig } from "../services/ConfigService";
import useTitle from "../hooks/useTitle";
import Button from "../components/io/Button";
import Icon from "../components/Icon";
import gameTitles from "../assets/game-titles.json";

import "../styles/views/newconfig.sass";
import "../styles/components/login-box.sass";

interface Props {
  user: User;
}

function ConfigCreator(props: Props): JSX.Element {
  useTitle("Create config");

  const navigate = useNavigate();

  useEffect(() => {
    if (props.user === null) {
      navigate("/login");
    }
  }, [props.user, navigate]);

  const handleSubmit = async (values: any) => {
    try {
      const config = await createConfig(
        {
          name: values.name,
          description: values.description || undefined,
          isPrivate: values.visibility === "private",
          game: values.gameId || undefined
        },
        getSessionKey() as string
      );

      navigate(`/configs/${config.id}`);
      setToast("ok", "Config created successfully");
    } catch {
      return setToast("error", "Failed to create config");
    }
  };

  return (
    <div className="new-config">
      <section className="new-config-content">
        <h1 className="config-creator-title">New config</h1>
        <Formik
          initialValues={{
            name: "",
            description: "",
            visibility: "public",
            gameId: "csgo"
          }}
          onSubmit={handleSubmit}
        >
          <Form className="form">
            <div className="form__item form__item--vertical">
              <label htmlFor="name" className="form__label">
                Name
              </label>
              <Field
                name="name"
                type="text"
                placeholder="Name"
                className="form__field"
              />
            </div>

            <div className="form__item form__item--vertical">
              <label htmlFor="description" className="form__label">
                Description
              </label>
              <Field
                type="text"
                as="textarea"
                name="description"
                placeholder="Description"
                className="form__field form__field--textarea config-creator-description"
              />
            </div>

            <div className="form__track form__track--responsive-sm">
              <div className="form__item form__item--vertical form__item--grow">
                <label htmlFor="gameId" className="form__label">
                  Game
                </label>

                <Field
                  name="gameId"
                  id="gameId"
                  as="select"
                  className="form__select"
                >
                  <option value="" label="Other" />
                  {Object.entries(gameTitles).map(([key, value]) => (
                    <option key={key} value={key} label={value} />
                  ))}
                </Field>
              </div>

              <div className="form__item form__item--vertical form__item--grow">
                <label htmlFor="visibility" className="form__label">
                  Visibility
                </label>

                <Field
                  name="visibility"
                  id="visibility"
                  as="select"
                  className="form__select"
                >
                  <option value="public" label="Public" />
                  <option value="private" label="Private" />
                </Field>
              </div>
            </div>

            <Button.Group grow={true}>
              <Button as="submit" type="ok" value="Create" icon="add" />
              <Link to="/" className="button button--blank button--icon">
                <Icon name="close" />
                Cancel
              </Link>
            </Button.Group>
          </Form>
        </Formik>
      </section>
    </div>
  );
}

function mapStateToProps(state: any) {
  return { user: state.user };
}

export default connect(mapStateToProps, null)(ConfigCreator);
