import { useRef, useEffect } from "react";

function useClickAreaObserver<T extends HTMLElement>(
  clickedOutside: () => void
) {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as T)) {
        clickedOutside();
      }
    };

    document.addEventListener("mouseup", handleClick);

    return () => {
      document.removeEventListener("mouseup", handleClick);
    };
  }, [ref]);

  return ref;
}

export default useClickAreaObserver;
