import { type FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";

import { saveSessionKey, setToast } from "../utils";
import { login, fetchIdentity } from "../services/UserService";
import { setUser } from "../actions";
import useTitle from "../hooks/useTitle";
import useTextField from "../hooks/io/useTextField";
import Button from "../components/io/Button";

import "../styles/views/login.sass";
import "../styles/components/login-box.sass";

export async function handleLogin(username: string, password: string) {
  try {
    const { sessionKey } = await login(username, password);
    setUser(await fetchIdentity(sessionKey));
    saveSessionKey(sessionKey);
    setToast("ok", "Logged in successfully");
    return true;
  } catch {
    setToast("error", "Unable to log in");
    return;
  }
}

export function LoginForm(props: {
  handleSubmit: (values: { username: string; password: string }) => void;
}) {
  return (
    <Formik
      initialValues={{
        username: "",
        password: ""
      }}
      onSubmit={props.handleSubmit}
    >
      <Form className="form">
        <div className="form__track form__track--vertical">
          <div className="form__item form__item--vertical form__item--grow">
            <label htmlFor="username" className="form__label">
              Username
            </label>
            <Field
              name="username"
              type="text"
              placeholder="Username"
              className="form__field"
            />
          </div>

          <div className="form__item form__item--vertical form__item--grow">
            <label htmlFor="password" className="form__label">
              Password
            </label>
            <Field
              name="password"
              type="password"
              placeholder="Password"
              className="form__field"
            />
          </div>
        </div>

        <div className="form__item form__item--vertical form__item--grow">
          <Button as="submit" type="ok" value="Log in" className="flex-fill" />
        </div>
      </Form>
    </Formik>
  );
}

function Login(): JSX.Element {
  useTitle("Log in");

  const navigate = useNavigate();

  async function handleFormSubmit(values: {
    username: string;
    password: string;
  }) {
    if (await handleLogin(values.username, values.password)) {
      navigate(`/users/${values.username}`);
    }
  }

  return (
    <section className="section">
      <div className="container container-xs">
        <h1 className="registration-title">Log in</h1>
        <div className="login-form">
          <LoginForm handleSubmit={handleFormSubmit} />
        </div>
      </div>
    </section>
  );
}

function mapStateToProps(state: any) {
  return { user: state.user };
}

export default connect(mapStateToProps, null)(Login);
