import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import {
  getSessionKey,
  transformISODate,
  getAvatarUrl,
  simulateDelay,
  setToast,
  getCountryName
} from "../utils";
import { fetchUserConfigs } from "../services/ConfigService";
import { fetchUserByName } from "../services/UserService";
import useTitle from "../hooks/useTitle";
import ConfigBox from "../components/ConfigBox";
import Avatar from "../components/Avatar";
import Icon from "../components/Icon";

import "../styles/views/profile.sass";

interface Props {
  identity?: User;
}

interface HeaderProps {
  isMutable: boolean;
  configCount?: number;
  user: User;
}

interface ContentProps {
  isMutable: boolean;
  configs: Config[] | null;
}

function displayConfigCount(count?: number) {
  if (typeof count === "number") {
    return `${count} ${count === 1 ? "configuration" : "configurations"}`;
  } else {
    return "Loading";
  }
}

function drawUserStats(stats: { icon: IconName; value: string }[]) {
  return (
    <ul className="user-stats">
      {stats.map(
        ({ icon, value }) =>
          value && (
            <div key={icon} className="user-stat-item">
              {icon === "earth" ? (
                <img
                  src={`https://flagsapi.com/${value}/flat/24.png`}
                  className="user-stat-flag-icon"
                />
              ) : (
                <Icon name={icon} />
              )}
              <p>
                {icon === "earth"
                  ? getCountryName(value as CountryCode)
                  : value}
              </p>
            </div>
          )
      )}
    </ul>
  );
}

Profile.Header = function Header(props: HeaderProps) {
  const { username, countryCode, createdAt } = props.user;

  const userStats = [
    { icon: "earth", value: countryCode },
    { icon: "clock", value: `Joined ${transformISODate(createdAt)}` },
    {
      icon: "gears",
      value: displayConfigCount(props.configCount)
    }
  ] as { icon: IconName; value: string }[];

  return (
    <section className="section section--small profile-view-header-section">
      <div className="container container-xxl profile-view-header">
        <Avatar
          isMutable={props.isMutable}
          userId={props.user.id}
          src={getAvatarUrl(props.user)}
        />

        <div className="profile-basic-info">
          <p className="username username-large">{username}</p>
          {drawUserStats(userStats)}
        </div>
      </div>
    </section>
  );
};

Profile.Content = function Content(props: ContentProps) {
  const configs = props.configs?.map((config) => (
    <li key={config.id}>
      <ConfigBox config={config} />
    </li>
  ));

  return (
    <section className="section">
      <div className="container container-xxl">
        <div className="profile-view-content-top">
          <p className="profile-view-configs-title">Configs</p>

          {props.configs && props.isMutable && (
            <div className="profile-view-config-buttons">
              <Link to="/new">
                <div className="profile-view-create-config button button--regular">
                  <Icon name="add" />
                  <p>Create</p>
                </div>
              </Link>
            </div>
          )}
        </div>

        {props.configs ? (
          <ol className="config-list">{configs}</ol>
        ) : (
          <div className="config-list-loading">
            <p>Loading configs</p>

            <div className="configs-loading-icon">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

function Profile(props: Props): JSX.Element {
  const [configs, setConfigs] = useState<Config[] | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isUserLoading, setIsUserLoading] = useState(true);

  useTitle(user?.username ?? undefined);

  const location = useLocation();

  const username = location.pathname.split("/").pop();

  useEffect(() => {
    loadConfigs();
  }, [user, props.identity]);

  useEffect(() => {
    loadUser();
  }, [username, props.identity]);

  if (!user && !isUserLoading) {
    setToast("error", "User not found");

    return <p className="profile-error">404: User not found</p>;
  }

  async function loadUser() {
    if (username) {
      if (username === props.identity?.username) {
        setUser(props.identity);
      } else {
        const user = await fetchUserByName(username);
        setIsUserLoading(false);
        if (user) {
          setUser(user);
        }
      }
    }
  }

  async function loadConfigs() {
    if (user) {
      setConfigs(
        await simulateDelay(
          fetchUserConfigs(user.id, props.identity ? getSessionKey() : null),
          500
        )
      );
    }
  }

  const isMutable = username === props.identity?.username;

  return (
    <main className="profile-view">
      {user ? (
        <>
          <Profile.Header
            isMutable={isMutable}
            configCount={configs?.length}
            user={user}
          />

          <Profile.Content isMutable={isMutable} configs={configs} />
        </>
      ) : null}
    </main>
  );
}

function mapStateToProps(state: any) {
  return { identity: state.user };
}

export default connect(mapStateToProps, null)(Profile);
