import axios, { type AxiosResponse } from "axios";
import { API_HOST, IMAGE_HOST } from "../config/env";
import { setToast as _setToast, clearToast } from "../actions";
import store from "../stores";
import gameTitles from "../assets/game-titles.json";
import countries from "../assets/countries.json";
import { fetchUser } from "../services/UserService";

export function simulateDelay<T>(
  promise: Promise<T>,
  duration: number
): Promise<T> {
  const symbol = Symbol();

  return Promise.all([
    promise,
    new Promise((resolve) => {
      setTimeout(resolve.bind(null, symbol), duration);
    })
  ])
    .then((results) => results.filter((result) => result !== symbol))
    .then((results) => results[0]) as Promise<T>;
}

async function handleResponse(response: Promise<AxiosResponse>) {
  return response
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const { response } = err;

      throw new Error(
        typeof response.data === "object"
          ? `${response.status} ${response.data.type}: ${response.data.payload.message}`
          : response.statusText
      );
    });
}

export function createRequestClient(baseUrl?: string) {
  const request = axios.create({
    baseURL: baseUrl ? API_HOST + baseUrl : API_HOST
  });

  return {
    get: (path: string, options?: any) =>
      handleResponse(request.get(path, options)),

    post: (path: string, data?: any, options?: any) =>
      handleResponse(request.post(path, data, options)),

    put: (path: string, data?: any, options?: any) =>
      handleResponse(request.put(path, data, options)),

    delete: (path: string, options?: any) =>
      handleResponse(request.delete(path, options))
  };
}

const sessionKeyStorageId = "glomfig_co__session_key";

export function saveSessionKey(sessionKey: string) {
  window.localStorage.setItem(sessionKeyStorageId, sessionKey);
}

export function getSessionKey() {
  return window.localStorage.getItem(sessionKeyStorageId);
}

export function clearSessionKey() {
  window.localStorage.removeItem(sessionKeyStorageId);
}

export function transformISODate(date: string) {
  return Reflect.construct(Date, [date]).toLocaleDateString("en-US", {
    month: "long",
    year: "numeric"
  });
}

export function getAvatarUrl(user?: User | null) {
  return user?.avatar ? `${IMAGE_HOST}/${user.avatar}` : null;
}

export function setToast(
  type: "ok" | "error",
  message: string,
  duration = 3000
) {
  const toastId = Symbol();
  _setToast(type, message, toastId);

  setTimeout(() => {
    const currentToastId = (store.getState() as Store).toast?.id;

    if (currentToastId === toastId) {
      clearToast();
    }
  }, duration);
}

declare global {
  type GameID = keyof typeof gameTitles;
}

export function isValidGameType(gameId: string) {
  return gameId && Reflect.has(gameTitles, gameId);
}

export function getGameTitle(gameId: GameID) {
  return gameTitles[gameId];
}

const countryNames = Object.entries(countries).reduce(
  (carry, [code, desc]) => ({ ...carry, [code]: desc.country }),
  {}
);

declare global {
  type CountryCode = keyof typeof countryNames;
}

export function getCountryName(countryCode: keyof typeof countries) {
  return (countryNames as Record<keyof typeof countries, string>)[countryCode];
}

export function getCountryNames() {
  return countryNames as Record<string, string>;
}

export function getTimeApproximation(duration: number) {
  if (duration < 60) {
    return "Just now";
  } else if (duration < 120) {
    return "A minute ago";
  } else if (duration < 3600) {
    return `${Math.trunc(duration / 60)} minutes ago`;
  } else if (duration < 7200) {
    return "An hour ago";
  } else if (duration < 86400) {
    return `${Math.trunc(duration / 3600)} hours ago`;
  } else if (duration < 172800) {
    return "Yesterday";
  } else if (duration < 604800) {
    return `${Math.trunc(duration / 86400)} days ago`;
  } else if (duration < 2592000) {
    const plural = Math.trunc(duration / 604800) > 1;
    return `${Math.trunc(duration / 604800)} ${plural ? "weeks" : "week"} ago`;
  } else if (duration < 31536000) {
    const plural = Math.trunc(duration / 2592000) > 1;
    return `${Math.trunc(duration / 2592000)} ${
      plural ? "months" : "month"
    } ago`;
  } else {
    return "A long time ago";
  }
}

export function capitalizeString(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function buildQueryString(queryObj: Record<string, string | number | (string | number)[] | undefined>) {
  return "?" + Object.entries(queryObj)
    .filter(([_, value]) => value)
    .map(([key, value]) =>
    Array.isArray(value)
      ? value.map((part) => `${key}=${part}`)
      : `${key}=${value}`
  ).flat().join("&");
}

export function createConfigDescriptors(configs: Config[]) {
  return configs.map(async (config) => {
    const owner = (await fetchUser(config.owner)) as User;

    return {
      id: config.id,
      name: config.name,
      game: config.game,
      ownerName: owner.username,
      ownerAvatar: (owner.avatar && getAvatarUrl(owner)) || undefined,
      createdAgo: getTimeApproximation(
        (Date.now() - Date.parse(config.createdAt)) / 1000
      )
    }
  });
}
