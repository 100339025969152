import { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import gameTitles from "../assets/game-titles.json";
import useTitle from "../hooks/useTitle";
import { capitalizeString, createConfigDescriptors, simulateDelay, isValidGameType } from "../utils";
import { fetchConfigs } from "../services/ConfigService";
import ConfigBox from "../components/ConfigBox";
import TypeBadge from "../components/TypeBadge";
import ConfigDescriptor from "../components/ConfigDescriptor";

import "../styles/views/explore.sass";

function isValidType(type?: string) {
  return !type || ["recent", "popular"].includes(type);
}

function drawConfigDescriptors(configDescriptors: ConfigDescriptor[], lastRef: any) {
  return configDescriptors.map((descriptor, i) => (
    <div key={descriptor.id} ref={i === configDescriptors.length - 1 ? lastRef : null}>
      <ConfigDescriptor descriptor={descriptor} />
    </div>
  ));
}

function drawLoadingAnimation(label: string) {
  return <div className="config-list-loading recent-configs-loading">
    <p>{label}</p>

    <div className="configs-loading-icon">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
}

function Explore() {
  const [configDescriptors, setConfigDescriptors] = useState<ConfigDescriptor[] | null>(null);
  const [selectedGameTypes, setSelectedGameTypes] = useState<(GameID | "other")[]>([]);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [fetchMore, setFetchMore] = useState(true);
  const lastRef = useRef(null);
  const { type } = useParams()
  const [searchParams, setSearchParams] = useSearchParams();

  const gameIds = searchParams.getAll("id");

  const title = isValidType(type)
    ? (
      type
        ? capitalizeString(type)
        : "Explore"
    ) + " configs"
    : "Page not found";

  useTitle(title);

  useEffect(() => {
    if (isValidType(type)) {
      loadConfigs(3);
    }
  }, [selectedGameTypes, type]);

  useEffect(() => {
    if (isIntersecting && fetchMore && configDescriptors?.length) {
      loadConfigs(configDescriptors?.length + 3);
    }
  }, [isIntersecting, fetchMore]);

  useEffect(() => {
    setSelectedGameTypes(gameIds.filter(isValidGameType) as GameID[]);
  }, []);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, { threshold: 1 });

    if (lastRef.current) {
      intersectionObserver.observe(lastRef.current);
    }

    return () => intersectionObserver.disconnect();
  })

  useEffect(() => {
    setSearchParams({ id: selectedGameTypes });
    
    loadConfigs(configDescriptors ? configDescriptors.length + 3 : 3);
  }, [selectedGameTypes]);

  async function loadConfigs(count: number) {
    try {
      const configs = await fetchConfigs(count, "desc", selectedGameTypes);
      if (configDescriptors && configs.length <= configDescriptors.length) {
        setFetchMore(false);
      }

      setConfigDescriptors(
        await simulateDelay(
          Promise.all(
            createConfigDescriptors(
              configs
            )
          ),
          500
        )
      );
    } catch {
      return;
    }
  }

  function toggleBadge(gameId: GameID) {
    if (selectedGameTypes.includes(gameId)) {
      setSelectedGameTypes(selectedGameTypes.filter((selectedGameType) => selectedGameType !== gameId));
    } else {
      setSelectedGameTypes(selectedGameTypes.concat(gameId));
    }
  }

  return (
    <main>
      {
        isValidType(type) ? <>
          <header className="section explorer-header">
            <div className="container container-md">

              <p className="explorer-header-title">
                {title}
              </p>

              <div className="explorer-header-type-badges">
                {
                  Object.entries(gameTitles).map(([key, value]) => (
                    <TypeBadge
                      key={key}
                      label={value}
                      gameId={key as GameID}
                      selected={selectedGameTypes.includes(key as GameID)}
                      onClick={toggleBadge.bind(null, key as GameID)}
                    />
                  ))
                }
              </div>
            </div>
          </header>

          <section className="section">
            <div className="container container-md">
              {
                configDescriptors && <div ref={lastRef} className="explorer-descriptor-list">
                  {drawConfigDescriptors(configDescriptors, lastRef)}
                </div>
              }
              {
                  (!configDescriptors || isIntersecting) && fetchMore && drawLoadingAnimation("Loading configs")
              }
            </div>
          </section>
        </> : (
          <p className="profile-error">
            404: Page not found
          </p>
        )
      }
    </main>
  );
}

export default Explore;
