import { type ReactNode } from "react";
import { Link } from "react-router-dom";
import "../styles/components/box.sass";

interface Props {
  className?: string;
  title: string;
  isPadded?: boolean;
  children: ReactNode;
}

interface BoxItemProps {
  value: string;
  displayId: string | undefined;
}

Box.Item = function BoxItem(props: BoxItemProps) {
  return (
    <div className="box-item">
      {" "}
      <Link className="box-link" to={`/configs/${props.displayId}`}>
        {" "}
        {props.value}
      </Link>
    </div>
  );
};

function Box(props: Props) {
  return (
    <div className={props.className ? `box ${props.className}` : "box"}>
      <div className="box-header">{props.title}</div>
      <div
        className={
          props.isPadded === false
            ? `box-content box-content-no-padding`
            : "box-content"
        }
      >
        {props.children}
      </div>
    </div>
  );
}

export default Box;
